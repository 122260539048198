import logo from "../logo.svg";
//import discordlogo from './discordlogo.png';
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { Program, BN, AnchorProvider, web3 } from "@project-serum/anchor";
import idl from "../idl.json";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  useWallet,
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Buffer } from "buffer";
import { v4 } from "uuid";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
  getAccount,
  createInitializeMintInstruction,
  createMint,
  getOrCreateAssociatedTokenAccount,
  mintTo,
  createAssociatedTokenAccount,
  getAssociatedTokenAddress,
  transfer,
} from "@solana/spl-token";
window.Buffer = Buffer;
require("@solana/wallet-adapter-react-ui/styles.css");

function formatTimeAgo(date) {
  const now = new Date();
  const dayDiff = differenceInDays(now, date);
  const hourDiff = differenceInHours(now, date) % 24;
  const minuteDiff = differenceInMinutes(now, date) % 60;

  let timeAgo = "";
  if (dayDiff > 0) {
    timeAgo += `${dayDiff}D`;
  }
  if (hourDiff > 0 || dayDiff > 0) {
    // Gün farkı varsa saat de ekleyin
    timeAgo += `${hourDiff}H`;
  }
  timeAgo += `${minuteDiff}M`;

  return timeAgo;
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Time's up!</span>;
  } else {
    // Render a countdown
    return (
      <span id="mycontttasd">
        {days}D{hours}H{minutes}M{seconds}S
      </span>
    );
  }
};
var _maximumfund = 0,
  _userlimit = 0,
  _minimumdeposit = 0,
  _maximumdeposit = 0,
  _poolrate = 0;

var __fundcollect = false,
  __fundback = false;

var ispoolinitialized = false;
const wallets = [new PhantomWalletAdapter()];
const { SystemProgram, Keypair } = web3;
const opts = {
  preflightCommitment: "processed",
};

const systID = new PublicKey("11111111111111111111111111111111");
const CLOCK_PROGRAM_ID = new PublicKey(
  "SysvarC1ock11111111111111111111111111111111"
);
const usdcaddress = new web3.PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);

const raddr = new PublicKey("A963Yro8x4wYMiYcQZqqwnR9L9GtDV6rCXY13hbvSUqG");

const programID = new PublicKey("3e56P7vB8W83SXmf3DoWD2TFvLfyBwtK3EXCt7P6gVhV");
//system account details.Pool account
const genesisacc = new web3.PublicKey(
  "2oz7qEazfYKHGwzpi3zE1YXexH57gBqL1qmMHQSDTsTN"
);

var load = false;
var poolpubkey;

const pathname = window.location.pathname;

function App() {
  const wallet = useWallet();
  const [walletKey, setWalletKey] = useState(null);
  const [buttonText, setButtonText] = useState("Initialize");
  const [poolButton, setpoolButton] = useState("Initialize pool");
  const [updatePoolButton, setupdatePoolButton] = useState("UPDATE POOL");

  const [poolRateButton, setpoolRateButton] = useState("UPDATE RATE");

  const [mdstatus, setMdstatus] = useState("MONEY DEPOSITE STATUS");

  const [functionCompleted, setFunctionCompleted] = useState(false);
  const [inputValues, setInputValues] = useState({});

  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [poollist, setpoollist] = useState([]);

  const [wrlist, setwrlist] = useState([]);

  const [checkboxStates, setCheckboxStates] = useState({
    _fundcollect: false,
    _fundback: false,
  });

  var psname = "PoolName";
  var plname = "PoolName";

  const updateCheckboxStates = (newStates) => {
    setCheckboxStates(newStates);
  };
  //Deposite and Withdrwal buttons

  function shortenText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
  }

  useEffect(() => {
    // Cüzdanın bağlantı durumunu kontrol eder ve değişiklik olduğunda state'i günceller
    setIsWalletConnected(wallet.connected);
  }, [wallet.connected]);

  useEffect(() => {
    if (isWalletConnected) {
      checkloadpool();
      load = false;
    } else {
      console.log("wallet is not connected.");
    }
  }, [isWalletConnected]);

  // Checkbox durumunu güncellemek için genel bir işleyici
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target; // Checkbox'ın adını ve kontrol durumunu al
    setCheckboxStates((prevState) => ({
      ...prevState,
      [name]: checked, // İlgili checkbox durumunu güncelle
    }));

    switch (name) {
      case "_fundcollect":
        console.log("_fundcollect is here ", checked);
        console.log("_fundcollect is here", name);
        __fundcollect = checked;
        if (checked) {
          setMdstatus("USDC DEPOSITE : ACTIVE");
        } else {
          setMdstatus("USDC DEPOSITE : CLOSED");
        }
        break;
      case "_fundback":
        console.log("_fundback is here ", checked);
        console.log("_fundback is here", name);
        __fundback = checked;
        break;
      default:
        console.log("im here");
    }

    console.log("__fundcollect", __fundcollect);
    console.log("__fundback", __fundback);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Düzenli ifade ile deseni kontrol et: En fazla 4 basamaklı tam sayılar ve isteğe bağlı iki basamaklı ondalık kısım

    if (name == "input5") {
      console.log("val is inp5");
      if (/^\d{0,3}(\.\d{0,0})?$/.test(value)) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        switch (name) {
          case "input5":
            console.log("input5 is here ", value);
            console.log("input5 is here", name);
            _poolrate = value;
            break;
          default:
            console.log("im here");
        }
      }
    } else {
      if (/^\d{0,6}(\.\d{0,0})?$/.test(value)) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        switch (name) {
          case "input1":
            console.log("input1value is here ", value);
            console.log("input1name is here", name);
            _maximumfund = value;
            break;
          case "input2":
            console.log("input2 is here ", value);
            console.log("input2 is here", name);
            _userlimit = value;
            break;

          case "input3":
            console.log("input3 is here ", value);
            console.log("input3 is here", name);
            _minimumdeposit = value;
            break;
          case "input4":
            console.log("input4 is here ", value);
            console.log("input4 is here", name);
            _maximumdeposit = value;
            break;
          default:
            console.log("im here");
        }
      }
    }
  };

  const qetProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const connectWallet = async () => {
    const provider = qetProvider();
    if (provider) {
      try {
        const response = await provider.connect();
        const pubKey = await provider.publicKey;
        console.log("userspubkey", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());

        // Kullanıcının cüzdan bağlantısını kestiğinde loglama yap
        provider.on("disconnect", () => {
          console.log("User has disconnected their wallet");
          // Burada gerekli diğer işlemler yapılabilir
          load = false;
        });

        provider.on("accountChanged", (publicKey) => {
          console.log("Hesap değişti:", publicKey.toBase58());
          load = false;
        });

        //
      } catch (err) {
        console.log("here failll");
      }
    } else {
      console.log("no provider right now");
    }
  };

  useEffect(() => connectWallet, []);

  async function getProvider() {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    const network =
      "https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b";
    const connection = new Connection(network, opts.preflightCommitment);

    const provider = new AnchorProvider(
      connection,
      wallet,
      opts.preflightCommitment
    );

    return provider;
  }

  async function repay(
    wramount,
    pubkey,
    depositerusdcaddr,
    depositersoladdr,
    depositeAmount
  ) {
    console.log("pkey", wramount);
    console.log("lendercc", pubkey);
    console.log("bowner", depositerusdcaddr);
    console.log("bowner", depositersoladdr);
    console.log("bowner", depositeAmount);

    const provider = await getProvider();
    /* create the program interface combining the idl, program ID, and provider */
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;

    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );

    let transaction1 = new web3.Transaction();
    transaction1.add(
      await program.methods
        .sendFundBack()
        .accounts({
          admin: User_Wallet.publicKey,
          usdcDepositer: pubkey,
          systemAccount: genesisacc,
          poolAccount: poolpubkey,
          toAta: depositerusdcaddr,
          poolownerusdcAta: associatedAddress,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    try {
      console.log("publicKey", User_Wallet.publicKey);
      const txid = await wallet.sendTransaction(
        transaction1,
        provider.connection
      );
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/" + txid;
      toast(({ closeToast }) => (
        <div>
          {" "}
          Updated{" "}
          <a target="_blank" href={mylink}>
            check from solana.fm{" "}
          </a>
        </div>
      ));
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error(`Update failed: ${error.message}`);
    }
  }

  async function checkTransactionFinalized(txid) {
    console.log("checkTransactionFinalized");
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    let finalized = false;

    while (!finalized) {
      try {
        // İşlem onay durumunu kontrol et
        const response = await program.provider.connection.getSignatureStatuses(
          [txid]
        );
        const status = response && response.value[0];

        if (status) {
          finalized = status.confirmationStatus === "finalized";

          if (finalized) {
            console.log("İşlem finalized oldu:", txid);
            load = false;
            checkloadpool();
          } else {
            console.log(
              "İşlem henüz finalized değil, tekrar kontrol ediliyor..."
            );
          }
        }
      } catch (error) {
        console.error("İşlem durumu kontrolü sırasında hata oluştu:", error);
      }

      // Belirli bir süre bekleyin (örneğin 5 saniye)
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  async function checkloadpool() {
    console.log("im here");
    if (load) {
      console.log("already intialized");
    } else {
      const provider = await getProvider();
      const program = new Program(idl, programID, provider);
      const pubKey = provider.publicKey;
      const User_Wallet = provider;

      try {
        const [connectyourpool, bump] = PublicKey.findProgramAddressSync(
          [Buffer.from("usdc-pool-details"), User_Wallet.publicKey.toBuffer()],
          programID
        );
        psname = shortenText(connectyourpool.toString(), 6);
        plname = "https://mfunds.io/#/pool/" + connectyourpool.toString();
        console.log("psname", psname);
        console.log("plname", plname);

        poolpubkey = connectyourpool; // new web3.PublicKey(pathname);

        console.log("connectyourpool", connectyourpool);
        var fetchangetpools = await program.account.poolAccount.fetch(
          connectyourpool
        );

        console.log("fetchangetpools", fetchangetpools);
        loadallpools(connectyourpool);
      } catch (error) {
        console.error("Kullanıcı daha önce pool yaratmamış.:", error);
      }
    }
  }

  async function loadallpools(loadpooldetails) {
    if (load) {
      console.log("already intialized");
    } else {
      console.log("loadallpools");
      let newButtons = [];

      const provider = await getProvider();
      const program = new Program(idl, programID, provider);
      const pubKey = provider.publicKey;
      const User_Wallet = provider;

      //Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*-    Fetch Pools -*- Fetch Pools -*-
      var [getyourpool] = web3.PublicKey.findProgramAddressSync(
        [
          Buffer.from("fetch-pool"),
          loadpooldetails.toBuffer(),
          provider.wallet.publicKey.toBuffer(),
        ],
        programID
      );

      try {
        var fetchangetpools = await program.account.poolAccount.fetch(
          loadpooldetails
        );
        setpoollist([]);
        console.log("fetchangetpools", fetchangetpools);

        console.log("Logistic", fetchangetpools);

        console.log("TVM", fetchangetpools.depositeamount.toNumber());
        console.log("TotalUser", fetchangetpools.usercounter);
        console.log("ROI", fetchangetpools.winrate.toNumber());
        console.log("Withdrawal", fetchangetpools.paybackamount.toNumber());

        for (let i = 0; i < fetchangetpools.usercounter; i++) {
          console.log("usercounter", i);

          const bufferr = Buffer.alloc(4); // create 4 byte buffer
          bufferr.writeUInt32LE(i, 0); // 'lid'  little-endian format
          const [usersdeposteaccount, bump] = PublicKey.findProgramAddressSync(
            [
              Buffer.from("usdc-depositer-acc"),
              loadpooldetails.toBuffer(),
              bufferr,
            ],
            programID
          );
          console.log("fetch succ", usersdeposteaccount.toString());

          var accountdetailz = await program.account.depositeAccount.fetch(
            usersdeposteaccount
          );

          console.log("accountdetailz rstatus", accountdetailz.rstatus);

          if (accountdetailz.rstatus) {
            console.log(
              "user wanna request his money right now!",
              accountdetailz.rwAmount.toNumber()
            );

            const wllistz = {
              wramount: accountdetailz.rwAmount.toNumber() / 1000000,
              pubkey: usersdeposteaccount.toString(),
              depositerusdcaddr: accountdetailz.depositerusdcaddr.toString(),
              depositersoladdr: accountdetailz.depositersoladdr.toString(),
              depositeAmount: accountdetailz.depositeAmount.toNumber(),
            };

            setwrlist((oldwllistz) => [...oldwllistz, wllistz]);
          } else {
            console.log("pass this account!");
          }
        }

        //if user already in pool ! if user already in pool ! if user already in pool ! if user already in pool !
        var [fethpoolaccount] = web3.PublicKey.findProgramAddressSync(
          [
            Buffer.from("fetch-pool"),
            loadpooldetails.toBuffer(),
            provider.wallet.publicKey.toBuffer(),
          ],
          programID
        );

        console.log("fethpoolaccount", fethpoolaccount);

        const accountInfo = await program.provider.connection.getAccountInfo(
          fethpoolaccount
        );

        if (accountInfo !== null) {
          var poolindex = await program.account.fetchPools.fetch(
            fethpoolaccount
          );

          console.log("poolindex", poolindex.index);

          console.log("fetchangetpools", fetchangetpools);
          const bufferr = Buffer.alloc(4); // create 4 byte buffer
          bufferr.writeUInt32LE(poolindex.index, 0); // 'lid'  little-endian format
          const [usersdeposteaccount, bump] = PublicKey.findProgramAddressSync(
            [
              Buffer.from("usdc-depositer-acc"),
              loadpooldetails.toBuffer(),
              bufferr,
            ],
            programID
          );
          console.log("usersdeposteaccount", usersdeposteaccount);
          var fetchuserdetails = await program.account.depositeAccount.fetch(
            usersdeposteaccount
          );
          console.log("your account details is here", fetchuserdetails);

          console.log("max fund is here", fetchangetpools.maxfund.toNumber());

          setInputValues((prevValues) => ({
            ...prevValues,
            input1: fetchangetpools.maxfund.toNumber(),
            input2: fetchangetpools.userlimit,
            input3: fetchangetpools.mindeposit.toNumber(),
            input4: fetchangetpools.maxdeposit.toNumber(),
          }));
          const plistz = {
            tvm: fetchangetpools.depositeamount.toNumber(),
            totaluser: fetchangetpools.usercounter,
            roi: fetchangetpools.winrate.toNumber() / 1000000,
            withdrawal: fetchangetpools.paybackamount.toNumber(),
            owner: fetchangetpools.ownersoladdr.toString(),
            usdcaddr: fetchangetpools.royaltyaddr.toString(),
            depositeamount: fetchuserdetails.depositeAmount.toNumber(),
            withdrawalamount: fetchuserdetails.withdrawalAmount.toNumber(),
            rwamount: fetchuserdetails.rwAmount.toNumber(),
            psname: psname,
            plname: plname,
            fundcollect: fetchuserdetails.fundcollect,
            fundback: fetchuserdetails.fundback,
            maxfund: fetchangetpools.maxfund,
            userlimit: fetchangetpools.userlimit,
            mindeposit: fetchangetpools.mindeposit,
            maxdeposit: fetchangetpools.maxdeposit,
          };

          updateCheckboxStates({
            _fundcollect: fetchangetpools.fundcollect,
            _fundback: fetchangetpools.fundback,
          });

          if (fetchangetpools.fundcollect) {
            setMdstatus("USDC DEPOSITE: ACTIVE");
          } else {
            setMdstatus("USDC DEPOSITE: CLOSED");
          }

          __fundcollect = fetchangetpools.fundcollect;
          __fundback = fetchangetpools.fundback;
          _maximumfund = fetchangetpools.maxfund.toNumber() / 1000000;
          _userlimit = fetchangetpools.userlimit;
          _minimumdeposit = fetchangetpools.mindeposit.toNumber() / 1000000;
          _maximumdeposit = fetchangetpools.maxdeposit.toNumber() / 1000000;

          console.log("plistz", plistz);
          setpoollist((oldplistz) => [...oldplistz, plistz]);
        } else {
          console.log("max fund is here", fetchangetpools.maxfund.toNumber());

          setInputValues((prevValues) => ({
            ...prevValues,
            input1: fetchangetpools.maxfund.toNumber() / 1000000,
            input2: fetchangetpools.userlimit,
            input3: fetchangetpools.mindeposit.toNumber() / 1000000,
            input4: fetchangetpools.maxdeposit.toNumber() / 1000000,
          }));

          updateCheckboxStates({
            _fundcollect: fetchangetpools.fundcollect,
            _fundback: fetchangetpools.fundback,
          });

          if (fetchangetpools.fundcollect) {
            setMdstatus("USDC DEPOSITE: ACTIVE");
          } else {
            setMdstatus("USDC DEPOSITE: CLOSED");
          }

          __fundcollect = fetchangetpools.fundcollect;
          __fundback = fetchangetpools.fundback;
          _maximumfund = fetchangetpools.maxfund.toNumber() / 1000000;
          _userlimit = fetchangetpools.userlimit;
          _minimumdeposit = fetchangetpools.mindeposit.toNumber() / 1000000;
          _maximumdeposit = fetchangetpools.maxdeposit.toNumber() / 1000000;

          console.log("poolindex is zero");
          const plistz = {
            tvm: fetchangetpools.depositeamount.toNumber(),
            totaluser: fetchangetpools.usercounter,
            roi: fetchangetpools.winrate.toNumber() / 1000000,
            withdrawal: fetchangetpools.paybackamount.toNumber(),
            owner: fetchangetpools.ownersoladdr.toString(),
            usdcaddr: fetchangetpools.royaltyaddr.toString(),
            fundcollect: fetchangetpools.fundcollect,
            fundback: fetchangetpools.fundback,
            depositeamount: 0,
            withdrawalamount: 0,
            rwamount: 0,
            psname: psname,
            plname: plname,
            maxfund: fetchangetpools.maxfund,
            userlimit: fetchangetpools.userlimit,
            mindeposit: fetchangetpools.mindeposit,
            maxdeposit: fetchangetpools.maxdeposit,
          };

          console.log("plistz", plistz);
          setpoollist((oldplistz) => [...oldplistz, plistz]);
        }

        ispoolinitialized = true;
      } catch (error) {
        console.error("Hesapları alırken hatax oluştu:", error);
      }
      //Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*-    Fetch Pools -*- Fetch Pools -*-

      load = true;
    }
  }

  async function initializepool() {
    console.log("init system account");
    const provider = await getProvider();
    /* create the program interface combining the idl, program ID, and provider */
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;
    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );
    console.log(
      "System owner's usdc token account is here!!",
      associatedAddress.toString()
    );
    const [poolaccount, bump] = PublicKey.findProgramAddressSync(
      [Buffer.from("usdc-pool-details"), User_Wallet.publicKey.toBuffer()],

      programID
    );

    console.log("Genesis account addr ", poolaccount.toString());
    let transaction1 = new web3.Transaction();
    transaction1.add(
      await program.methods
        .initializePool(
          bump,
          new BN(_maximumfund * 1000000),
          new BN(_userlimit),
          new BN(_minimumdeposit * 1000000),
          new BN(_maximumdeposit * 1000000)
        )
        .accounts({
          admin: User_Wallet.publicKey,
          poolAccount: poolaccount,
          systemAccount: genesisacc,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          toAta: raddr,
          roy: associatedAddress,
        })
        .instruction()
    );
    try {
      console.log("asdasda", User_Wallet.publicKey);
      const txid = await wallet.sendTransaction(
        transaction1,
        provider.connection
      );
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/" + txid;
      toast(({ closeToast }) => (
        <div>
          {" "}
          Initilized{" "}
          <a target="_blank" href={mylink}>
            check from solana.fm{" "}
          </a>
        </div>
      ));
      checkloadpool();
    } catch (error) {
      console.log(error);
    }
  }

  async function updatepool() {
    console.log("__fundcollect", __fundcollect);
    console.log("__fundback", __fundback);
    console.log("_maximumfund", _maximumfund);
    console.log("_userlimit", _userlimit);
    console.log("_minimumdeposit", _minimumdeposit);
    console.log("_maximumdeposit", _maximumdeposit);

    const provider = await getProvider();
    /* create the program interface combining the idl, program ID, and provider */
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;

    const [poolaccount, bump] = PublicKey.findProgramAddressSync(
      [Buffer.from("usdc-pool-details"), User_Wallet.publicKey.toBuffer()],
      programID
    );

    console.log("Genesis account addr ", poolaccount.toString());
    let transaction1 = new web3.Transaction();
    transaction1.add(
      await program.methods
        .editPoolDetails(
          bump,
          __fundcollect,
          new BN(_maximumfund * 1000000),
          new BN(_userlimit),
          new BN(_minimumdeposit * 1000000),
          new BN(_maximumdeposit * 1000000)
        )
        .accounts({
          admin: User_Wallet.publicKey,
          poolAccount: poolaccount,
        })
        .instruction()
    );
    try {
      console.log("publicKey", User_Wallet.publicKey);
      const txid = await wallet.sendTransaction(
        transaction1,
        provider.connection
      );
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/" + txid;
      toast(({ closeToast }) => (
        <div>
          {" "}
          Updated{" "}
          <a target="_blank" href={mylink}>
            check from solana.fm{" "}
          </a>
        </div>
      ));
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error(`Update failed: ${error.message}`);
    }
  }

  async function updaterate() {
    const provider = await getProvider();
    /* create the program interface combining the idl, program ID, and provider */
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;

    const [poolaccount, bump] = PublicKey.findProgramAddressSync(
      [Buffer.from("usdc-pool-details"), User_Wallet.publicKey.toBuffer()],
      programID
    );

    console.log("Genesis account addr ", poolaccount.toString());
    let transaction1 = new web3.Transaction();
    transaction1.add(
      await program.methods
        .editPoolRate(bump, new BN(_poolrate))
        .accounts({
          admin: User_Wallet.publicKey,
          poolAccount: poolaccount,
        })
        .instruction()
    );
    try {
      console.log("publicKey", User_Wallet.publicKey);
      const txid = await wallet.sendTransaction(
        transaction1,
        provider.connection
      );
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/" + txid;
      toast(({ closeToast }) => (
        <div>
          {" "}
          Updated{" "}
          <a target="_blank" href={mylink}>
            check from solana.fm{" "}
          </a>
        </div>
      ));
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error(`Update failed: ${error.message}`);
    }
  }

  return (
    <div className="App">
      <Navbar id="nbb">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              id="loqo"
              src={logo}
              width="158"
              height="50"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
          <div className="ms-auto">
            <div className="wallet-button">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          </div>
        </Container>
      </Navbar>

      <header className="App-header">
        <div className="containerx">
          {poollist.map((item) => (
            <div key={v4()}>
              <Table
                striped
                hover
                size="lg"
                variant="transparent"
                className="transparent-table"
              >
                <thead>
                  <tr>
                    <th>Pool Name</th>
                    <th>TVM</th>
                    <th>ROI</th>
                    <th>Pool Status</th>
                    <th>Share</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.psname}</td>
                    <td>{item.tvm / 1000000} $</td>
                    <td>{item.roi}x </td>
                    <td>
                      {item.fundcollect
                        ? "Deposits are active"
                        : item.fundback
                        ? "Withdrawals are active"
                        : "Waiting - Deposits are closed"}
                    </td>
                    <td>
                      <a href={item.plname}>Your pool</a>
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th>Total user</th>
                    <th>Maximum Fund</th>
                    <th>User Limit</th>
                    <th>Maximum Deposit</th>
                    <th>Minimum Deposit</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> {item.totaluser} </td>
                    <td> {item.maxfund / 1000000} $ </td>
                    <td>{item.userlimit} </td>
                    <td>{item.maxdeposit / 1000000}$</td>
                    <td>{item.mindeposit / 1000000}$</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ))}
          <hr></hr> <hr></hr>
          {isWalletConnected ? (
            <>
              {ispoolinitialized ? (
                <>
                  <p className="form-check-label ftxtc">MAXIMUM FUND</p>
                  <Form.Control
                    name="input1"
                    className="custom-input"
                    type="text"
                    placeholder="maximum fund"
                    value={inputValues.input1 || ""}
                    onChange={handleInputChange}
                  />

                  <hr></hr>
                  <p className="form-check-label ftxtc">USER LIMIT</p>

                  <Form.Control
                    name="input2"
                    className="custom-input"
                    type="text"
                    placeholder="user limit"
                    value={inputValues.input2 || ""}
                    onChange={handleInputChange}
                  />

                  <hr></hr>
                  <p className="form-check-label ftxtc">MINIMUM DEPOSIT</p>
                  <Form.Control
                    name="input3"
                    className="custom-input"
                    type="text"
                    placeholder="minimum deposit"
                    value={inputValues.input3 || ""}
                    onChange={handleInputChange}
                  />

                  <hr></hr>
                  <p className="form-check-label ftxtc">MAXIMUM DEPOSIT</p>
                  <Form.Control
                    name="input4"
                    className="custom-input"
                    type="text"
                    placeholder="maximum deposit"
                    value={inputValues.input4 || ""}
                    onChange={handleInputChange}
                  />

                  <hr></hr>
                  <hr></hr>
                  <hr></hr>
                  <hr></hr>

                  <div className="form-check form-switch fs-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="_fundcollect"
                      id="_fundcollect"
                      checked={checkboxStates._fundcollect}
                      onChange={handleCheckboxChange} // Değişiklik olduğunda işleyiciyi çağır
                    />
                    <label
                      className="form-check-label ftxtc"
                      htmlFor="exampleCheckbox"
                      id="mdstatus"
                    >
                      {mdstatus}
                    </label>
                  </div>

                  <hr></hr>
                  <hr></hr>

                  <Button
                    className="lendbtn"
                    variant="outline-success"
                    type="button"
                    onClick={updatepool}
                  >
                    {updatePoolButton}
                  </Button>

                  <hr></hr>
                  <hr></hr>

                  <Form.Control
                    name="input5"
                    className="custom-input"
                    type="text"
                    placeholder="% interest rate ratio"
                    value={inputValues.input5 || ""}
                    onChange={handleInputChange}
                  />

                  <Button
                    className="lendbtn"
                    variant="outline-success"
                    type="button"
                    onClick={updaterate}
                  >
                    {poolRateButton}
                  </Button>
                </>
              ) : (
                <>
                  <p className="ftxtw">
                    You dont have a pool please initialize first!
                  </p>

                  <Form.Control
                    name="input1"
                    className="custom-input"
                    type="text"
                    placeholder="maximum fund"
                    value={inputValues.input1 || ""}
                    onChange={handleInputChange}
                  />

                  <Form.Control
                    name="input2"
                    className="custom-input"
                    type="text"
                    placeholder="user limit"
                    value={inputValues.input2 || ""}
                    onChange={handleInputChange}
                  />

                  <Form.Control
                    name="input3"
                    className="custom-input"
                    type="text"
                    placeholder="minimum deposite"
                    value={inputValues.input3 || ""}
                    onChange={handleInputChange}
                  />
                  <Form.Control
                    name="input4"
                    className="custom-input"
                    type="text"
                    placeholder="maximum deposit"
                    value={inputValues.input4 || ""}
                    onChange={handleInputChange}
                  />

                  <Button
                    className="lendbtn"
                    variant="outline-success"
                    type="button"
                    onClick={initializepool}
                  >
                    {poolButton}
                  </Button>
                </>
              )}
            </>
          ) : (
            <div className="wallet-button wbtn">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          )}
        </div>

        <div className="containerx">
          <p className="ftxtc">All withdrawal requests will be shown here.</p>

          {wrlist.map((item) => (
            <div key={v4()}>
              <p className="ftxtw">{item.wramount}</p>

              <Button
                className="lendbtn"
                variant="outline-success"
                onClick={() =>
                  repay(
                    item.wramount,
                    item.pubkey,
                    item.depositerusdcaddr,
                    item.depositersoladdr,
                    item.depositeAmount
                  )
                }
              >
                PayBack
              </Button>
            </div>
          ))}
        </div>
      </header>

      <footer className="footer mt-auto py-3" id="mf">
        <Container>
          <Row>
            <Col className="text-white text-center semi-bold-text">
              <span className="ftxt">
                {" "}
                DECENTRALIZED FUND MANAGER bv1.0.0 - Pool{" "}
              </span>{" "}
              <br></br>
              <span className="ftxtw">
                {" "}
                @2024 All Right Reserved.{" "}
                <a target="_blank" href="https://twitter.com/ManagementLabs">
                  {" "}
                  @ManagementLabs{" "}
                </a>
              </span>
              <br></br>
              <span className="ftxtw">
                <hr></hr>
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </div>
  );
}

const PoolPage = () => (
  <ConnectionProvider endpoint="https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
);

export default PoolPage;
