import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

// Sayfa bileşenlerini import et
import IndexPage from "./pages/index";
import PoolPage from "./pages/pool";
import AdminPage from "./pages/admin";
import DetailsPage from "./pages/details";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/pool" element={<PoolPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<DetailsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
