import logo from "../logo.svg";
//import discordlogo from './discordlogo.png';
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { Program, BN, AnchorProvider, web3 } from "@project-serum/anchor";
import idl from "../idl.json";
import { Alert } from "react-bootstrap";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  useWallet,
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Buffer } from "buffer";
import { v4 } from "uuid";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
  getAccount,
  createInitializeMintInstruction,
  createMint,
  getOrCreateAssociatedTokenAccount,
  mintTo,
  createAssociatedTokenAccount,
  getAssociatedTokenAddress,
  transfer,
} from "@solana/spl-token";
window.Buffer = Buffer;
require("@solana/wallet-adapter-react-ui/styles.css");

function formatTimeAgo(date) {
  const now = new Date();
  const dayDiff = differenceInDays(now, date);
  const hourDiff = differenceInHours(now, date) % 24;
  const minuteDiff = differenceInMinutes(now, date) % 60;

  let timeAgo = "";
  if (dayDiff > 0) {
    timeAgo += `${dayDiff}D`;
  }
  if (hourDiff > 0 || dayDiff > 0) {
    // Gün farkı varsa saat de ekleyin
    timeAgo += `${hourDiff}H`;
  }
  timeAgo += `${minuteDiff}M`;

  return timeAgo;
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Time's up!</span>;
  } else {
    // Render a countdown
    return (
      <span id="mycontttasd">
        {days}D{hours}H{minutes}M{seconds}S
      </span>
    );
  }
};
var depositeamount = 0;
const wallets = [new PhantomWalletAdapter()];
const { SystemProgram, Keypair } = web3;
const opts = {
  preflightCommitment: "processed",
};

const systID = new PublicKey("11111111111111111111111111111111");
const CLOCK_PROGRAM_ID = new PublicKey(
  "SysvarC1ock11111111111111111111111111111111"
);
const usdcaddress = new web3.PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);

const raddr = new PublicKey("A963Yro8x4wYMiYcQZqqwnR9L9GtDV6rCXY13hbvSUqG");

const programID = new PublicKey("3e56P7vB8W83SXmf3DoWD2TFvLfyBwtK3EXCt7P6gVhV");
//system account details.Pool account
const genesisacc = new web3.PublicKey(
  "2oz7qEazfYKHGwzpi3zE1YXexH57gBqL1qmMHQSDTsTN"
);

var load = false;

const pathname = window.location.pathname;

function App() {
  console.log("pathname", pathname);
  const wallet = useWallet();
  const [walletKey, setWalletKey] = useState(null);
  const [buttonText, setButtonText] = useState("Initialize");
  const [poolButton, setpoolButton] = useState("Initialize pool");

  const [functionCompleted, setFunctionCompleted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [poollist, setpoollist] = useState([]);

  useEffect(() => {
    // Cüzdanın bağlantı durumunu kontrol eder ve değişiklik olduğunda state'i günceller
    setIsWalletConnected(wallet.connected);
  }, [wallet.connected]);

  useEffect(() => {
    if (isWalletConnected) {
      console.log("wallet connected.");

      load = false;
    } else {
      console.log("wallet is not connected.");
    }
  }, [isWalletConnected]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Düzenli ifade ile deseni kontrol et: En fazla üç basamaklı tam sayılar ve isteğe bağlı iki basamaklı ondalık kısım
    if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
      console.log(value);
      setInputValue(value);
      depositeamount = value;
    }
  };

  const qetProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const connectWallet = async () => {
    const provider = qetProvider();
    if (provider) {
      try {
        const response = await provider.connect();
        const pubKey = await provider.publicKey;
        console.log("userspubkey", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());

        // Kullanıcının cüzdan bağlantısını kestiğinde loglama yap
        provider.on("disconnect", () => {
          console.log("User has disconnected their wallet");
          // Burada gerekli diğer işlemler yapılabilir
          load = false;
        });

        provider.on("accountChanged", (publicKey) => {
          console.log("Hesap değişti:", publicKey.toBase58());
          load = false;
        });

        //
      } catch (err) {
        console.log("here failll");
      }
    } else {
      console.log("no provider right now");
    }
  };

  useEffect(() => connectWallet, []);

  async function getProvider() {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    const network =
      "https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b";
    const connection = new Connection(network, opts.preflightCommitment);

    const provider = new AnchorProvider(
      connection,
      wallet,
      opts.preflightCommitment
    );

    return provider;
  }

  async function checkTransactionFinalized(txid) {
    console.log("checkTransactionFinalized");
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    let finalized = false;

    while (!finalized) {
      try {
        // İşlem onay durumunu kontrol et
        const response = await program.provider.connection.getSignatureStatuses(
          [txid]
        );
        const status = response && response.value[0];

        if (status) {
          finalized = status.confirmationStatus === "finalized";

          if (finalized) {
            console.log("İşlem finalized oldu:", txid);
            load = false;
          } else {
            console.log(
              "İşlem henüz finalized değil, tekrar kontrol ediliyor..."
            );
          }
        }
      } catch (error) {
        console.error("İşlem durumu kontrolü sırasında hata oluştu:", error);
      }

      // Belirli bir süre bekleyin (örneğin 5 saniye)
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  async function initialize() {
    console.log("init system account");
    const provider = await getProvider();
    /* create the program interface combining the idl, program ID, and provider */
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;
    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );
    console.log(
      "System owner's usdc token account is here!!",
      associatedAddress.toString()
    );
    const [ssaccount, bump] = PublicKey.findProgramAddressSync(
      [Buffer.from("system-settings")],
      programID
    );
    console.log("Genesis account addr ", ssaccount.toString());
    let transaction1 = new web3.Transaction();
    transaction1.add(
      await program.methods
        .systemSettings(bump, new BN(1), new BN(10 * 1000000))
        .accounts({
          admin: User_Wallet.publicKey,
          settingsAccount: ssaccount,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          roy: associatedAddress,
        })
        .instruction()
    );
    try {
      console.log("asdasda", User_Wallet.publicKey);
      const txid = await wallet.sendTransaction(
        transaction1,
        provider.connection
      );
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/" + txid;
      toast(({ closeToast }) => (
        <div>
          {" "}
          Initilized{" "}
          <a target="_blank" href={mylink}>
            check from solana.fm{" "}
          </a>
        </div>
      ));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App">
      <Navbar id="nbb">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              id="loqo"
              src={logo}
              width="158"
              height="50"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
          <div className="ms-auto">
            <div className="wallet-button">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          </div>
        </Container>
      </Navbar>

      <header className="App-header">
        <div className="containerx">
          {isWalletConnected ? (
            <Button
              variant="outline-secondary"
              className="subbtn"
              onClick={initialize}
            >
              {buttonText}
            </Button>
          ) : (
            <div className="wallet-button wbtn">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          )}
        </div>
      </header>

      <footer className="footer mt-auto py-3" id="mf">
        <Container>
          <Row>
            <Col className="text-white text-center semi-bold-text">
              <span className="ftxt"> DECENTRALIZED FUND MANAGER bv1.1.0 </span>{" "}
              <br></br>
              <span className="ftxtw">
                {" "}
                @2024 All Rights Reserved.{" "}
                <a target="_blank" href="https://twitter.com/ManagementLabs">
                  {" "}
                  @ManagementLabs{" "}
                </a>
              </span>
              <br></br>
              <span className="ftxtw">
                <hr></hr>
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </div>
  );
}

const AdminPage = () => (
  <ConnectionProvider endpoint="https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
);

export default AdminPage;
