import logo from "../logo.svg";
//import discordlogo from './discordlogo.png';
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { Program, BN, AnchorProvider, web3 } from "@project-serum/anchor";
import idl from "../idl.json";
import { Alert } from "react-bootstrap";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  useWallet,
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Buffer } from "buffer";
import { v4 } from "uuid";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
  getAccount,
  createInitializeMintInstruction,
  createMint,
  getOrCreateAssociatedTokenAccount,
  mintTo,
  createAssociatedTokenAccount,
  getAssociatedTokenAddress,
  transfer,
} from "@solana/spl-token";
window.Buffer = Buffer;
require("@solana/wallet-adapter-react-ui/styles.css");

function formatTimeAgo(date) {
  const now = new Date();
  const dayDiff = differenceInDays(now, date);
  const hourDiff = differenceInHours(now, date) % 24;
  const minuteDiff = differenceInMinutes(now, date) % 60;

  let timeAgo = "";
  if (dayDiff > 0) {
    timeAgo += `${dayDiff}D`;
  }
  if (hourDiff > 0 || dayDiff > 0) {
    // Gün farkı varsa saat de ekleyin
    timeAgo += `${hourDiff}H`;
  }
  timeAgo += `${minuteDiff}M`;

  return timeAgo;
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Time's up!</span>;
  } else {
    // Render a countdown
    return (
      <span id="mycontttasd">
        {days}D{hours}H{minutes}M{seconds}S
      </span>
    );
  }
};
var depositeamount = 0;
const wallets = [new PhantomWalletAdapter()];
const { SystemProgram, Keypair } = web3;
const opts = {
  preflightCommitment: "processed",
};

const systID = new PublicKey("11111111111111111111111111111111");
const CLOCK_PROGRAM_ID = new PublicKey(
  "SysvarC1ock11111111111111111111111111111111"
);
const usdcaddress = new web3.PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);
const raddr = new PublicKey("A963Yro8x4wYMiYcQZqqwnR9L9GtDV6rCXY13hbvSUqG");

const programID = new PublicKey("3e56P7vB8W83SXmf3DoWD2TFvLfyBwtK3EXCt7P6gVhV");
//system account details.Pool account
const genesisacc = new web3.PublicKey(
  "2oz7qEazfYKHGwzpi3zE1YXexH57gBqL1qmMHQSDTsTN"
);

var poolpubkey;

var load = false;

var withdrawalmode = false;

function App() {
  const wallet = useWallet();
  const [walletKey, setWalletKey] = useState(null);
  const [buttonText, setButtonText] = useState("Deposit");

  const [functionCompleted, setFunctionCompleted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [poollist, setpoollist] = useState([]);

  //Deposite and Withdrwal buttons

  const [buttons, setButtons] = useState([]); // State olarak buttons dizisini tanımlayın

  function MyButton({ id, buttonText }) {
    return (
      <Button
        className="lendbtn"
        variant="outline-secondary"
        type="button"
        onClick={() => deposite()}
        id={id}
      >
        {buttonText}
      </Button>
    );
  }

  function WithdrawalButton({ id, buttonText }) {
    return (
      <Button
        className="lendbtn"
        variant="outline-secondary"
        type="button"
        onClick={() => withdrawal()}
        id={id}
      >
        {buttonText}
      </Button>
    );
  }

  //Deposite and Withdrwal buttons

  useEffect(() => {
    // Cüzdanın bağlantı durumunu kontrol eder ve değişiklik olduğunda state'i günceller
    setIsWalletConnected(wallet.connected);
  }, [wallet.connected]);

  useEffect(() => {
    if (isWalletConnected) {
      console.log("wallet connected.");
      try {
        let pathname = window.location.hash.split("/").pop();
        const ppKey = new web3.PublicKey(pathname);
        console.log("Geçerli bir Solana adresi");
        poolpubkey = ppKey;
        loadallpools();
        load = false;
      } catch (error) {
        console.log("Geçersiz Solana adresi");
      }
    } else {
      console.log("wallet is not connected.");
    }
  }, [isWalletConnected]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Düzenli ifade ile deseni kontrol et: En fazla üç basamaklı tam sayılar ve isteğe bağlı iki basamaklı ondalık kısım
    if (/^\d{0,5}(\.\d{0,5})?$/.test(value)) {
      console.log(value);
      setInputValue(value);
      depositeamount = value;
    }
  };

  function shortenText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
  }

  const qetProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const connectWallet = async () => {
    const provider = qetProvider();
    if (provider) {
      try {
        const response = await provider.connect();
        const pubKey = await provider.publicKey;
        console.log("userspubkey", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());

        // Kullanıcının cüzdan bağlantısını kestiğinde loglama yap
        provider.on("disconnect", () => {
          console.log("User has disconnected their wallet");
          // Burada gerekli diğer işlemler yapılabilir
          load = false;
        });

        provider.on("accountChanged", (publicKey) => {
          console.log("Hesap değişti:", publicKey.toBase58());
          load = false;
        });

        //
      } catch (err) {
        console.log("here failll");
      }
    } else {
      console.log("no provider right now");
    }
  };

  useEffect(() => connectWallet, []);

  async function getProvider() {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    const network =
      "https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b";
    const connection = new Connection(network, opts.preflightCommitment);

    const provider = new AnchorProvider(
      connection,
      wallet,
      opts.preflightCommitment
    );

    return provider;
  }

  async function checkTransactionFinalized(txid) {
    console.log("checkTransactionFinalized");
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    let finalized = false;

    while (!finalized) {
      try {
        // İşlem onay durumunu kontrol et
        const response = await program.provider.connection.getSignatureStatuses(
          [txid]
        );
        const status = response && response.value[0];

        if (status) {
          finalized = status.confirmationStatus === "finalized";

          if (finalized) {
            console.log("İşlem finalized oldu:", txid);
            load = false;
            loadallpools();
          } else {
            console.log(
              "İşlem henüz finalized değil, tekrar kontrol ediliyor..."
            );
          }
        }
      } catch (error) {
        console.error("İşlem durumu kontrolü sırasında hata oluştu:", error);
      }

      // Belirli bir süre bekleyin (örneğin 5 saniye)
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  function formatAmount(amount, decimals = 6) {
    // Miktarı desimal sayısına böl
    const dividedAmount = amount / Math.pow(10, decimals);

    // Sonucu string olarak al
    let amountStr = dividedAmount.toString();

    // Noktadan sonra en fazla 2 rakam olacak şekilde kes
    let dotIndex = amountStr.indexOf(".");
    if (dotIndex !== -1) {
      // Nokta varsa, noktadan sonra 2 karakteri koru
      amountStr = amountStr.substring(0, dotIndex + 3);
    }

    // Formatlanmış sonucu döndür
    return amountStr;
  }

  async function loadallpools() {
    if (load) {
      console.log("already intialized");
    } else {
      console.log("loadallpools");
      let newButtons = [];

      const provider = await getProvider();
      const program = new Program(idl, programID, provider);
      const pubKey = provider.publicKey;
      const User_Wallet = provider;

      //Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*-    Fetch Pools -*- Fetch Pools -*-
      try {
        var fetchangetpools = await program.account.poolAccount.fetch(
          poolpubkey
        );
        setpoollist([]);
        console.log("fetchangetpools", fetchangetpools);

        console.log("Logistic", fetchangetpools);

        console.log("TVM", fetchangetpools.depositeamount.toNumber());
        console.log("TotalUser", fetchangetpools.usercounter);
        console.log("ROI", fetchangetpools.winrate.toNumber());
        console.log("Withdrawal", fetchangetpools.paybackamount.toNumber());

        //if user already in pool ! if user already in pool ! if user already in pool ! if user already in pool !
        var [fethpoolaccount] = web3.PublicKey.findProgramAddressSync(
          [
            Buffer.from("fetch-pool"),
            poolpubkey.toBuffer(),
            provider.wallet.publicKey.toBuffer(),
          ],
          programID
        );

        console.log("fethpoolaccount", fethpoolaccount);

        const accountInfo = await program.provider.connection.getAccountInfo(
          fethpoolaccount
        );

        if (accountInfo !== null) {
          var poolindex = await program.account.fetchPools.fetch(
            fethpoolaccount
          );

          console.log("poolindex", poolindex.index);

          console.log("fetchangetpools", fetchangetpools);
          const bufferr = Buffer.alloc(4); // create 4 byte buffer
          bufferr.writeUInt32LE(poolindex.index, 0); // 'lid'  little-endian format
          const [usersdeposteaccount, bump] = PublicKey.findProgramAddressSync(
            [Buffer.from("usdc-depositer-acc"), poolpubkey.toBuffer(), bufferr],
            programID
          );
          console.log("usersdeposteaccount", usersdeposteaccount);
          var fetchuserdetails = await program.account.depositeAccount.fetch(
            usersdeposteaccount
          );
          console.log("fetchangetpools", fetchangetpools);

          let depositeAmount = new BN(fetchuserdetails.depositeAmount);
          let winrate = new BN(fetchangetpools.winrate);
          // Hesaplama yapılıyor
          let depositeAmountUpdated = depositeAmount
            .mul(winrate)
            .div(new BN(1000000));

          depositeAmountUpdated = formatAmount(depositeAmountUpdated);

          let rw = formatAmount(fetchuserdetails.withdrawalAmount);

          let pbaupdated = formatAmount(fetchangetpools.paybackamount);

          const plistz = {
            tvm: fetchangetpools.depositeamount.toNumber(),
            totaluser: fetchangetpools.usercounter,
            roi: pbaupdated,
            withdrawal: fetchangetpools.paybackamount.toNumber(),
            owner: fetchangetpools.ownersoladdr.toString(),
            usdcaddr: fetchangetpools.royaltyaddr.toString(),
            depositeamount: depositeAmountUpdated,
            withdrawalamount: rw,
            rwamount: fetchuserdetails.rwAmount.toNumber(),
            fundcollect: fetchangetpools.fundcollect,
            fundback: fetchangetpools.fundback,
            shortpoolname: shortenText(poolpubkey.toString(), 6),
            maxfund: fetchangetpools.maxfund,
            userlimit: fetchangetpools.userlimit,
            mindeposit: fetchangetpools.mindeposit,
            maxdeposit: fetchangetpools.maxdeposit,
          };

          console.log("plistz", plistz);
          setpoollist((oldplistz) => [...oldplistz, plistz]);
        } else {
          let pbaupdated = formatAmount(fetchangetpools.paybackamount);

          console.log("fetchangetpools", fetchangetpools);
          console.log("poolindex is zero");
          const plistz = {
            tvm: fetchangetpools.depositeamount.toNumber(),
            totaluser: fetchangetpools.usercounter,
            roi: pbaupdated,
            withdrawal: fetchangetpools.paybackamount.toNumber(),
            owner: fetchangetpools.ownersoladdr.toString(),
            usdcaddr: fetchangetpools.royaltyaddr.toString(),
            depositeamount: 0,
            withdrawalamount: 0,
            rwamount: 0,
            fundcollect: fetchangetpools.fundcollect,
            fundback: fetchangetpools.fundback,
            shortpoolname: shortenText(poolpubkey.toString(), 6),
            maxfund: fetchangetpools.maxfund,
            userlimit: fetchangetpools.userlimit,
            mindeposit: fetchangetpools.mindeposit,
            maxdeposit: fetchangetpools.maxdeposit,
          };

          console.log("plistz", plistz);
          setpoollist((oldplistz) => [...oldplistz, plistz]);
        }

        if (fetchangetpools.fundback) {
          withdrawalmode = true;
          const buttonId = 1; // Her butona özgü bir ID
          const buttonText = `Withdrawal`; // Her butona özgü bir metin
          newButtons.push(
            <WithdrawalButton key={1} id={buttonId} buttonText={buttonText} />
          );
          setButtons(newButtons);
        } else if (fetchangetpools.fundcollect) {
          const buttonId = 1; // Her butona özgü bir ID
          const buttonText = `Deposit`; // Her butona özgü bir metin
          newButtons.push(
            <MyButton key={1} id={buttonId} buttonText={buttonText} />
          );
          setButtons(newButtons);
        }
      } catch (error) {
        console.error("Hesapları alırken hatax oluştu:", error);
        toast.error(`Update failed: ${error.message}`);
      }
      //Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*- Fetch Pools -*-    Fetch Pools -*- Fetch Pools -*-

      load = true;
    }
  }

  async function deposite() {
    console.log("poolpubkey is here", poolpubkey);
    console.log("deposite to pool", depositeamount);

    if (isWalletConnected == false) {
      toast(({ closeToast }) => <div> Connect with your wallet first. </div>);
    } else if (depositeamount < 1) {
      //let mylink = "https://explorer.solana.com/tx/"+txx;
      console.log("minimum 1 usdc req", depositeamount);
      toast(({ closeToast }) => <div> Minimum 1 USDC required. </div>);
    } else if (depositeamount > 15000) {
      //let mylink = "https://explorer.solana.com/tx/"+txx;
      console.log("Maxium 15000 usdc allowed");
      toast(({ closeToast }) => <div> Maximum 15000 USDC allowed. </div>);
    } else {
      const provider = await getProvider();
      const program = new Program(idl, programID, provider);
      const pubKey = provider.publicKey;
      const User_Wallet = provider;

      const associatedAddress = await getAssociatedTokenAddress(
        usdcaddress,
        User_Wallet.publicKey
      );

      console.log("coming here...");
      var [fethpoolaccount] = web3.PublicKey.findProgramAddressSync(
        [
          Buffer.from("fetch-pool"),
          poolpubkey.toBuffer(),
          provider.wallet.publicKey.toBuffer(),
        ],
        programID
      );
      const accountInfo = await program.provider.connection.getAccountInfo(
        fethpoolaccount
      );
      var getusercounter = 0;

      var fetchpooldetailz = await program.account.poolAccount.fetch(
        poolpubkey
      );
      console.log(fetchpooldetailz);
      let pooluscaddr = fetchpooldetailz.royaltyaddr;

      if (accountInfo !== null) {
        console.log(
          "user deposited usdc before this pool",
          fethpoolaccount.toString()
        );
        var poolindex = await program.account.fetchPools.fetch(fethpoolaccount);
        console.log(poolindex.index);
        getusercounter = poolindex.index;
        console.log("accountInfo diye bir şey var ", accountInfo);
      } else {
        console.log("accountInfo diye bir şey yok ", accountInfo);
        console.log(
          "fetchpooldetailz.usercounter",
          fetchpooldetailz.usercounter
        );
        getusercounter = fetchpooldetailz.usercounter;
        console.log("else", getusercounter);
      }

      console.log("Pool usdc hesabı", pooluscaddr.toString());
      console.log("getusercounter is here", getusercounter);

      console.log("user counter is here bro", getusercounter);
      const bufferr = Buffer.alloc(4); // create 4 byte buffer
      bufferr.writeUInt32LE(getusercounter, 0); // 'lid'  little-endian format
      const [userusdcaccount, bump] = PublicKey.findProgramAddressSync(
        [Buffer.from("usdc-depositer-acc"), poolpubkey.toBuffer(), bufferr],
        programID
      );
      const [poolindexaccount, bumpx] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("fetch-pool"),
          poolpubkey.toBuffer(),
          User_Wallet.publicKey.toBuffer(),
        ],
        programID
      );

      console.log("is pool index same", poolindexaccount.toString());

      console.log("is useruscacc   same", userusdcaccount.toString());

      let accountExists = false;
      try {
        const accountInfotwo = await program.provider.connection.getAccountInfo(
          associatedAddress
        );
        accountExists = accountInfotwo !== null;
      } catch (error) {
        console.log("Error checking if account exists:", error);
        toast.error(`Update failed: ${error.message}`);
      }

      let transaction1 = new web3.Transaction();

      if (!accountExists) {
        const createATAInstruction = createAssociatedTokenAccountInstruction(
          User_Wallet.publicKey, // Ödeme yapan
          associatedAddress, // Oluşturulacak token hesabı adresi
          User_Wallet.publicKey, // Token hesabının sahibi
          usdcaddress // Token mint adresi
        );
        transaction1.add(createATAInstruction);
      }

      transaction1.add(
        await program.methods
          .depositeAccount(
            getusercounter,
            bump,
            new BN(depositeamount * 1000000)
          )
          .accounts({
            admin: User_Wallet.publicKey,
            usdcDepositer: userusdcaccount,
            systemAccount: genesisacc,
            poolAccount: poolpubkey,
            poolIndex: poolindexaccount,
            systemProgram: web3.SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            toAta: pooluscaddr, // Pool owners usdc address.
            userAta: associatedAddress, // Users usdc address.
            systemusdc: raddr, //System owners usdc address.
          })
          .signers([User_Wallet])
          .instruction()
      );

      try {
        console.log("asdasda", User_Wallet.publicKey);
        const txid = await wallet.sendTransaction(
          transaction1,
          provider.connection
        );
        console.log(txid);
        let mylink = "https://explorer.solana.com/tx/" + txid;
        toast(({ closeToast }) => (
          <div>
            {" "}
            Deposite succesfull{" "}
            <a target="_blank" href={mylink}>
              check from solana.fm{" "}
            </a>
          </div>
        ));
        checkTransactionFinalized(txid);
      } catch (error) {
        console.log(error);
        toast.error(`Update failed: ${error.message}`);
      }
    }
  }

  async function withdrawal() {
    console.log("poolpubkey is here", poolpubkey);
    console.log("deposite to pool", depositeamount);

    if (isWalletConnected == false) {
      toast(({ closeToast }) => <div> Connect with your wallet first. </div>);
    } else if (depositeamount < 1) {
      //let mylink = "https://explorer.solana.com/tx/"+txx;
      console.log("minimum 1 usdc req", depositeamount);
      toast(({ closeToast }) => <div> Minimum 1 USDC required. </div>);
    } else if (depositeamount > 10000) {
      //let mylink = "https://explorer.solana.com/tx/"+txx;
      console.log("Maximum 10000 usdc allowed");
      toast(({ closeToast }) => <div> Maximum 10000 USDC allowed. </div>);
    } else {
      const provider = await getProvider();
      const program = new Program(idl, programID, provider);
      const pubKey = provider.publicKey;
      const User_Wallet = provider;

      const associatedAddress = await getAssociatedTokenAddress(
        usdcaddress,
        User_Wallet.publicKey
      );

      console.log("coming here...");
      var [fethpoolaccount] = web3.PublicKey.findProgramAddressSync(
        [
          Buffer.from("fetch-pool"),
          poolpubkey.toBuffer(),
          provider.wallet.publicKey.toBuffer(),
        ],
        programID
      );
      const accountInfo = await program.provider.connection.getAccountInfo(
        fethpoolaccount
      );
      var getusercounter = 0;

      var fetchpooldetailz = await program.account.poolAccount.fetch(
        poolpubkey
      );
      console.log(fetchpooldetailz);
      let pooluscaddr = fetchpooldetailz.royaltyaddr;

      if (accountInfo !== null) {
        console.log(
          "user deposited usdc before this pool",
          fethpoolaccount.toString()
        );
        var poolindex = await program.account.fetchPools.fetch(fethpoolaccount);
        console.log(poolindex.index);
        getusercounter = poolindex.index;
        console.log("accountInfo diye bir şey var ", accountInfo);
      } else {
        console.log("accountInfo diye bir şey yok ", accountInfo);
        console.log(
          "fetchpooldetailz.usercounter",
          fetchpooldetailz.usercounter
        );
        getusercounter = fetchpooldetailz.usercounter;
        console.log("else", getusercounter);
      }

      console.log("Pool usdc hesabı", pooluscaddr.toString());
      console.log("getusercounter is here", getusercounter);

      console.log("user counter is here bro", getusercounter);
      const bufferr = Buffer.alloc(4); // create 4 byte buffer
      bufferr.writeUInt32LE(getusercounter, 0); // 'lid'  little-endian format
      const [userusdcaccount, bump] = PublicKey.findProgramAddressSync(
        [Buffer.from("usdc-depositer-acc"), poolpubkey.toBuffer(), bufferr],
        programID
      );
      const [poolindexaccount, bumpx] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("fetch-pool"),
          poolpubkey.toBuffer(),
          User_Wallet.publicKey.toBuffer(),
        ],
        programID
      );

      console.log("is pool index same", poolindexaccount.toString());

      console.log("is useruscacc   same", userusdcaccount.toString());

      let accountExists = false;
      try {
        const accountInfotwo = await program.provider.connection.getAccountInfo(
          associatedAddress
        );
        accountExists = accountInfotwo !== null;
      } catch (error) {
        console.log("Error checking if account exists:", error);
        toast.error(`Update failed: ${error.message}`);
      }

      let transaction1 = new web3.Transaction();

      if (!accountExists) {
        const createATAInstruction = createAssociatedTokenAccountInstruction(
          User_Wallet.publicKey, // Ödeme yapan
          associatedAddress, // Oluşturulacak token hesabı adresi
          User_Wallet.publicKey, // Token hesabının sahibi
          usdcaddress // Token mint adresi
        );
        transaction1.add(createATAInstruction);
      }

      transaction1.add(
        await program.methods
          .withrawalRequest(new BN(depositeamount * 1000000))
          .accounts({
            admin: User_Wallet.publicKey,
            systemAccount: genesisacc,
            requester: userusdcaccount,
            poolAccount: poolpubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .signers([User_Wallet])
          .instruction()
      );

      try {
        console.log("asdasda", User_Wallet.publicKey);
        const txid = await wallet.sendTransaction(
          transaction1,
          provider.connection
        );
        console.log(txid);
        let mylink = "https://explorer.solana.com/tx/" + txid;
        toast(({ closeToast }) => (
          <div>
            {" "}
            Deposite succesfull{" "}
            <a target="_blank" href={mylink}>
              check from solana.fm{" "}
            </a>
          </div>
        ));
        checkTransactionFinalized(txid);
      } catch (error) {
        console.log(error);
        toast.error(`Update failed: ${error.message}`);
      }
    }
  }

  return (
    <div className="App">
      <Navbar id="nbb">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              id="loqo"
              src={logo}
              width="158"
              height="50"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
          <div className="ms-auto">
            <div className="wallet-button">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          </div>
        </Container>
      </Navbar>

      <header className="App-header">
        <div className="containerx">
          {poollist.map((item) => (
            <div key={v4()}>
              <Table
                striped
                hover
                size="lg"
                variant="transparent"
                className="transparent-table"
              >
                <thead>
                  <tr>
                    <th>Pool Name</th>
                    <th>TVM</th>
                    <th>Total Withdrawal</th>
                    <th>Pool Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.shortpoolname.toString()}</td>
                    <td>{Math.trunc(item.tvm / 1000000)} $</td>
                    <td>{item.roi}$ </td>
                    <td>
                      {item.fundcollect
                        ? "Deposits are active"
                        : item.fundback
                        ? "Withdrawals are active"
                        : "Waiting - Deposits are closed"}
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th>Total user</th>
                    <th>Your Deposit</th>
                    <th>Your Withdrawal</th>
                    <th>Requested Withdrawal</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> {item.totaluser} </td>
                    <td>{item.depositeamount} $</td>
                    <td>{item.withdrawalamount}$</td>
                    <td>{item.rwamount / 1000000}$</td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th>Maximum Fund</th>
                    <th>User Limit</th>
                    <th>Maximum Deposit</th>
                    <th>Minimum Deposit</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> {item.maxfund / 1000000} $ </td>
                    <td>{item.userlimit} </td>
                    <td>{item.maxdeposit / 1000000}$</td>
                    <td>{item.mindeposit / 1000000}$</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ))}

          {isWalletConnected ? (
            <>
              <Form.Control
                id="{item.poolid}"
                name="{item.poolid}"
                className="custom-input"
                type="text"
                placeholder="usdc amount"
                value={inputValue}
                onChange={handleInputChange}
              />

              {withdrawalmode ? buttons : buttons}
            </>
          ) : (
            <div className="wallet-button wbtn">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          )}
        </div>
      </header>

      <footer className="footer mt-auto py-3" id="mf">
        <Container>
          <Row>
            <Col className="text-white text-center semi-bold-text">
              <span className="ftxt">
                {" "}
                DECENTRALIZED FUND MANAGER bv1.0.0 - Details
              </span>{" "}
              <br></br>
              <span className="ftxtw">
                {" "}
                @2024 All Right Reserved.{" "}
                <a target="_blank" href="https://twitter.com/ManagementLabs">
                  {" "}
                  @ManagementLabs{" "}
                </a>
              </span>
              <br></br>
              <br></br>
              <span className="ftxtw"></span>
            </Col>
          </Row>
        </Container>
      </footer>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </div>
  );
}

const DetailsPage = () => (
  <ConnectionProvider endpoint="https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
);

export default DetailsPage;
