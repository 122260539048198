import logo from "../logo.svg";
//import discordlogo from './discordlogo.png';
import cpool from "../createpool.png";
import xicon from "../xicon.png";
import dicon from "../dicon.png";
import wpicon from "../wpicon.png";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { Program, BN, AnchorProvider, web3 } from "@project-serum/anchor";
import idl from "../idl.json";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  useWallet,
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Buffer } from "buffer";
import { v4 } from "uuid";
import "../App.css";
import Navbar from "react-bootstrap/Navbar";
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
  getAccount,
  createInitializeMintInstruction,
  createMint,
  getOrCreateAssociatedTokenAccount,
  mintTo,
  createAssociatedTokenAccount,
  getAssociatedTokenAddress,
  transfer,
} from "@solana/spl-token";
window.Buffer = Buffer;
require("@solana/wallet-adapter-react-ui/styles.css");

function formatTimeAgo(date) {
  const now = new Date();
  const dayDiff = differenceInDays(now, date);
  const hourDiff = differenceInHours(now, date) % 24;
  const minuteDiff = differenceInMinutes(now, date) % 60;

  let timeAgo = "";
  if (dayDiff > 0) {
    timeAgo += `${dayDiff}D`;
  }
  if (hourDiff > 0 || dayDiff > 0) {
    // Gün farkı varsa saat de ekleyin
    timeAgo += `${hourDiff}H`;
  }
  timeAgo += `${minuteDiff}M`;

  return timeAgo;
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Time's up!</span>;
  } else {
    // Render a countdown
    return (
      <span id="mycontttasd">
        {days}D{hours}H{minutes}M{seconds}S
      </span>
    );
  }
};

const wallets = [new PhantomWalletAdapter()];
const { SystemProgram, Keypair } = web3;
const opts = {
  preflightCommitment: "processed",
};

const systID = new PublicKey("11111111111111111111111111111111");
const CLOCK_PROGRAM_ID = new PublicKey(
  "SysvarC1ock11111111111111111111111111111111"
);
const usdcaddress = new web3.PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);
const raddr = new PublicKey("A963Yro8x4wYMiYcQZqqwnR9L9GtDV6rCXY13hbvSUqG");

const programID = new PublicKey("3e56P7vB8W83SXmf3DoWD2TFvLfyBwtK3EXCt7P6gVhV");
//system account details.Pool account
const genesisacc = new web3.PublicKey(
  "2oz7qEazfYKHGwzpi3zE1YXexH57gBqL1qmMHQSDTsTN"
);

var load = false;
var withdrawalmode = false;

function App() {
  const wallet = useWallet();
  const [walletKey, setWalletKey] = useState(null);
  const [buttonText, setButtonText] = useState("Deposit");

  const [functionCompleted, setFunctionCompleted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [poollist, setpoollist] = useState([]);
  const [systemDetails, setsystemDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadallpools();
  }, []); // Boş bağımlılık dizisi, fonksiyonun sadece komponent yüklendiğinde bir kez çağrılmasını sağlar.

  useEffect(() => {
    // Cüzdanın bağlantı durumunu kontrol eder ve değişiklik olduğunda state'i günceller
    setIsWalletConnected(wallet.connected);
  }, [wallet.connected]);

  useEffect(() => {
    if (isWalletConnected) {
      console.log("wallet connected.");
    } else {
      console.log("wallet is not connected.");
    }
  }, [isWalletConnected]);

  const qetProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const connectWallet = async () => {
    const provider = qetProvider();
    if (provider) {
      try {
        const response = await provider.connect();
        const pubKey = await provider.publicKey;
        console.log("userspubkey", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());

        // Kullanıcının cüzdan bağlantısını kestiğinde loglama yap
        provider.on("disconnect", () => {
          console.log("User has disconnected their wallet");
          // Burada gerekli diğer işlemler yapılabilir
          load = false;
        });

        provider.on("accountChanged", (publicKey) => {
          console.log("Hesap değişti:", publicKey.toBase58());
          load = false;
        });

        //
      } catch (err) {
        console.log("here failll");
      }
    } else {
      console.log("no provider right now");
    }
  };

  useEffect(() => connectWallet, []);

  async function getProvider() {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    const network =
      "https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b";
    const connection = new Connection(network, opts.preflightCommitment);

    const provider = new AnchorProvider(
      connection,
      wallet,
      opts.preflightCommitment
    );

    return provider;
  }

  async function deposite(id) {
    //navigate(`${id}`);
    navigate(`/fund/${id}`);

    console.log(id);
  }

  async function checkTransactionFinalized(txid) {
    console.log("checkTransactionFinalized");
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    let finalized = false;

    while (!finalized) {
      try {
        // İşlem onay durumunu kontrol et
        const response = await program.provider.connection.getSignatureStatuses(
          [txid]
        );
        const status = response && response.value[0];

        if (status) {
          finalized = status.confirmationStatus === "finalized";

          if (finalized) {
            console.log("İşlem finalized oldu:", txid);
            load = false;
            loadallpools();
          } else {
            console.log(
              "İşlem henüz finalized değil, tekrar kontrol ediliyor..."
            );
          }
        }
      } catch (error) {
        console.error("İşlem durumu kontrolü sırasında hata oluştu:", error);
      }

      // Belirli bir süre bekleyin (örneğin 5 saniye)
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  function shortenText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "..";
  }

  async function loadallpools() {
    console.log("come here");
    if (load) {
      console.log("already initialized");
    } else {
      console.log("loadallpools");
      load = true;

      const provider = await getProvider();
      const program = new Program(idl, programID, provider);

      try {
        const fetchgenesisacc =
          await program.account.systemsettingsAccount.fetch(genesisacc);

        console.log(fetchgenesisacc);
        const syslistz = {
          sytotalpools: fetchgenesisacc.poolcounter,
          systotaldepo: fetchgenesisacc.totaldeposit.toNumber() / 1000000,
          systotalwith: fetchgenesisacc.totalwithdrawal.toNumber() / 1000000,
        };

        const fetchangetpools = await program.account.poolAccount.all();
        let newPlistz = []; // Yeni havuz listesi için boş bir dizi
        fetchangetpools.forEach((pool, index) => {
          let poolpubkey = new web3.PublicKey(pool.publicKey.toString());

          const plistz = {
            tvm: pool.account.depositeamount.toNumber(),
            totaluser: pool.account.usercounter,
            roi: pool.account.winrate.toNumber(),
            withdrawal: pool.account.paybackamount.toNumber(),
            owner: pool.account.ownersoladdr.toString(),
            usdcaddr: pool.account.royaltyaddr.toString(),
            fundcollect: pool.account.fundcollect,
            fundback: pool.account.fundback,
            buttonid: poolpubkey.toString(),
            shortpoolname: shortenText(poolpubkey.toString(), 3),
          };

          newPlistz.push(plistz); // plistz'yi newPlistz listesine ekle
        });

        setpoollist(newPlistz); // Tüm havuz listesini güncelle
        setsystemDetails(syslistz);
      } catch (error) {
        console.error("Fetching pools error:", error);
      }
    }
  }

  return (
    <div className="App">
      <Navbar id="nbb">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              id="loqo"
              src={logo}
              width="158"
              height="50"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
          <div className="ms-auto">
            <div className="wallet-button">
              {" "}
              <WalletMultiButton />{" "}
            </div>
          </div>
        </Container>
      </Navbar>

      <header className="App-header">
        <h1 id="headmsg">
          <a href="#/pool"> Become Fund Manager </a>
        </h1>

        <h1 id="headesp">or supply USDC to your trusted fund manager.</h1>

        <div className="containerx">
          <div className="containery">
            <div className="item">
              <p className="title">Total Pools</p>
              <p className="value">{systemDetails.sytotalpools}</p>
            </div>
            <div className="item">
              <p className="title">Total Deposit</p>
              <p className="value">{systemDetails.systotaldepo}$</p>
            </div>
            <div className="item">
              <p className="title">Total Withdrawal</p>
              <p className="value">{systemDetails.systotalwith}$</p>
            </div>
          </div>
        </div>
      </header>

      <hr id="mhr"></hr>

      <div className="poollistdiv">
        <Table
          striped
          hover
          size="lg"
          variant="transparent"
          className="transparent-table"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>TVM</th>
              <th>Pool Status</th>
              <th>Total user</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {poollist.map((item) => (
              <tr key={v4()}>
                <td>{item.shortpoolname}</td>
                <td>{Math.trunc(item.tvm / 1000000)} $</td>
                {/* <td>{item.roi}x </td> */}
                <td>
                  {item.fundcollect
                    ? "Deposits are active"
                    : item.fundback
                    ? "Withdrawals are active"
                    : "Deposits are closed"}
                </td>
                <td> {item.totaluser} </td>
                <td>
                  <Button
                    className="lendbtn"
                    variant="outline-secondary"
                    type="button"
                    onClick={() => deposite(item.buttonid)}
                  >
                    Join
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <footer className="footer mt-auto py-3" id="mf">
        <Container>
          <Row>
            <Col className="text-white text-center semi-bold-text">
              <span className="ftxt"> DECENTRALIZED FUND MANAGER bv1.0.0 </span>{" "}
              <br></br>
              <span className="ftxtw">
                {" "}
                @2024 All Rights Reserved.{" "}
                <a target="_blank" href="https://twitter.com/ManagementLabs">
                  {" "}
                  @ManagementLabs{" "}
                </a>
              </span>
              <br></br>
              <br></br>
              <span className="ftxtw"></span>
            </Col>
          </Row>
        </Container>

        <a href="#/pool" className="fixed-image-link">
          <img src={cpool} alt="create pool" className="fixed-image" />
        </a>

        <a
          href="https://twitter.com/ManagementLabs"
          target="_blank"
          className="fixed-left-image-link"
        >
          <img src={xicon} alt="create pool" className="fixed-l-image" />
        </a>

        <a
          href="https://discord.gg/ApVydr9w3p"
          target="_blank"
          className="fixed-left-image-link-two"
        >
          <img src={dicon} alt="create pool" className="fixed-l-image-two" />
        </a>

        <a
          href="https://management-labs.gitbook.io/mfunds.io"
          target="_blank"
          className="fixed-left-image-link-three"
        >
          <img src={wpicon} alt="create pool" className="fixed-l-image-three" />
        </a>
      </footer>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </div>
  );
}

const IndexPage = () => (
  <ConnectionProvider endpoint="https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
);

export default IndexPage;
